import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import styled from '@emotion/styled';
import {
  Box,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  Button,
  Hidden,
  Checkbox,
  Alert,
  Select,
  SelectChangeEvent,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import ReactGA from 'react-ga4';

import { Container, Gap, Row, Spacer } from 'components';
import { WidgetConfiguration, getApi, getCdnPath } from 'framework';
import signupLogo from '../../images/signupLogo.png';
import signupLogos from '../../images/signupLogos2023.png';

const RootContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: ${p => p.theme.palette.primary.main};
`;

const SignupContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${p => p.theme.palette.primary.main};
  padding-left: ${p => p.theme.spacing(5)};
  padding-right: ${p => p.theme.spacing(3)};
  padding-bottom: ${p => p.theme.spacing(4)};
  max-width: 1600px;
  margin: 0 auto;
`;

export const Line = styled(Box)`
  margin: ${p => p.theme.spacing(2, 0)};
  border-bottom: 2px solid #ffffffff;
  width: 80%;
  margin-left: 6%;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
`;

const ImagesBrandsContainer = styled(Box)`
  display: flex;
  flex-direction: row;

  img {
    width: 85%;

    ${p => p.theme.breakpoints.down('md')} {
      width: 100%;
    }
    object-fit: scale-down;
    margin: auto;
  }
`;

const Header = styled(Box)`
  background-color: ${p => p.theme.palette.primary.main};
  padding-top: ${p => p.theme.spacing(1)};
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;

  img {
    margin-left: ${p => p.theme.spacing(3)};
    ${p => p.theme.breakpoints.down('md')} {
      margin: auto;
    }
  }
`;

const FormWrapper = styled(Paper)`
  padding: ${p => p.theme.spacing(4)};
  box-shadow: 2px 2px 8px #868474ff;
`;

const MainTitle = styled(Typography)`
  color: ${p => p.theme.palette.primary.contrastText};
  text-shadow: 1px 1px #868474ff;
  margin: ${p => p.theme.spacing(3, 3)};

  font-size: 2.4em;
  ${p => p.theme.breakpoints.down('md')} {
    font-size: 1.8em;
  }
`;

const BrandTitle = styled(Typography)`
  color: ${p => p.theme.palette.primary.contrastText};
  text-shadow: 1px 1px #868474ff;
  text-align: center;
  font-weight: 500;

  font-size: 1.4em;
  ${p => p.theme.breakpoints.down('md')} {
    font-size: 1.2em;
  }
`;

const ClosingTitle = styled(Typography)`
  color: ${p => p.theme.palette.primary.contrastText};
  text-shadow: 1px 1px #868474ff;
  text-align: center;

  font-size: 1.4em;
  ${p => p.theme.breakpoints.down('md')} {
    font-size: 1.2em;
  }
`;

const SignupLeftTitle = styled(Typography)`
  color: ${p => p.theme.palette.primary.contrastText};
  text-shadow: 1px 1px #868474ff;
  text-align: center;
  margin: ${p => p.theme.spacing(3, 3)};

  font-size: 2.4em;
  ${p => p.theme.breakpoints.down('md')} {
    font-size: 1.8em;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const PrivacyNotice = styled(Typography)`
  padding: ${p => p.theme.spacing(2)};
`;

const TermsOfService = styled(Typography)`
  padding: ${p => p.theme.spacing(2)};
`;

const StyledTextField = styled(TextField)`
  background-color: #fff;
  border-radius: 4px;
`;

const StyledSelect = styled(
  Select<number | null>
) // eslint-disable-line
`
  background-color: #fff;
  border-radius: 4px;
`;

const BodyText = styled(Typography)`
  color: ${p => p.theme.palette.primary.contrastText};
  width: 100%;
  font-size: 1em;
  font-weight: 400;
  text-shadow: 1px 1px #868474ff;
`;

const errors = {
  slugFailed: 'signupSlugFailed',
  logoUrlFailed: 'signupLogoUrlFailed',
  logoUploadFailed: 'signupLogoUploadFailed',
  retailerFailed: 'signupRetailerFailed',
  userFailed: 'signupUserFailed',
  userEmailAlreadyExists: 'signupUserAlreadyExists'
};

const widgetColor = '#50aa8d';
const fontColor = '#ffffff';

export function Signup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [retailerName, setRetailerName] = useState('');
  const [retailerUrl, setRetailerUrl] = useState('');
  const [ownerEmail, setOwnerEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [annualRevenue, setAnnualRevenue] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [couponCode, setCouponCode] = useState<string | null>(null);
  const [error, setError] = useState<string | undefined>();

  const logo = `${getCdnPath()}/faslet%20demo/logo.png`;

  useEffect(() => {
    ReactGA.gtag('config', 'AW-10891217363');
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname,
      title: `signup-0`
    });

    ReactGA.gtag('event', 'conversion', {
      send_to: 'AW-10891217363/zV_4CPHdl8ADENOTq8ko'
    });
  }, [location.pathname]);

  const urlIsValid = (url: string) => {
    return url.length !== 0 && url.includes('.');
  };

  const emailIsValid = useCallback((email: string) => {
    return email.match(/.+@.+\..+/);
  }, []);

  const emailServerAllowed = useCallback((email: string) => {
    return !email.match(
      /(gmail|outlook.com|hotmail.com|protonmail|yahoo|googlemail)/gi
    );
  }, []);

  const isFirstStepComplete = useCallback(
    () =>
      urlIsValid(retailerUrl) &&
      !!retailerName &&
      !!ownerEmail &&
      !!firstName &&
      !!lastName,
    [retailerUrl, retailerName, ownerEmail, firstName, lastName]
  );

  const gotoSecondStep = useCallback(() => {
    const form = document.getElementById(
      'faslet-portal-signup-form'
    ) as HTMLFormElement;

    if (form) {
      form.submit();
    }
  }, []);

  const createStoreAndUser = useCallback(
    async (e?: React.FormEvent) => {
      e?.preventDefault();

      setLoading(true);

      const slugResponse = await fetch(
        `${getApi()}/retailer/slugify/${retailerName}`,
        {
          method: 'GET'
        }
      );

      if (!slugResponse.ok) {
        setError(errors.slugFailed);
        return;
      }

      const slug = await slugResponse.text();

      const retailerResponse = await fetch(`${getApi()}/retailer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          slug,
          name: retailerName,
          url: retailerUrl,
          logoS3: logo,
          widgetConfiguration: {
            colorPrimary: widgetColor,
            fontColor,
            sizingAlgorithm: 'cheddar',
            showButtonAndResult: true,
            buttonFillParent: true,
            buttonCallToAction: {
              enabled: true,
              delay: 2000,
              showAfterPageLoads: 2,
              showOnlyOnce: false,
              alignment: 'auto'
            },
            effects: {
              buttonShake: true
            }
          } as Partial<WidgetConfiguration>
        })
      });

      if (!retailerResponse.ok) {
        setError(errors.retailerFailed);
        return;
      }

      const { id } = await retailerResponse.json();

      const signupObject: Record<string, unknown> = {
        retailerId: id,
        firstName,
        lastName,
        phoneNumber,
        annualRevenue,
        email: ownerEmail
      };

      if (couponCode) {
        signupObject.coupon = couponCode;
      }

      const userSignUpResponse = await fetch(`${getApi()}/user/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(signupObject)
      });

      if (!userSignUpResponse.ok) {
        if (userSignUpResponse.status === 409) {
          setError(errors.userEmailAlreadyExists);
        } else {
          setError(errors.userFailed);
        }
        return;
      }

      setLoading(false);

      navigate(`/signup-complete/${slug}`);
    },
    [
      navigate,
      setLoading,
      setError,
      firstName,
      lastName,
      ownerEmail,
      phoneNumber,
      annualRevenue,
      retailerName,
      retailerUrl,
      couponCode
    ]
  );

  return (
    <RootContainer>
      <Header>
        <img
          src={signupLogo}
          alt={t('logo')}
          height={80}
          width={140}
          style={{ objectFit: 'scale-down' }}
        />
      </Header>
      <Gap size={4} />
      <SignupContainer>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Container>
              <Container>
                <SignupLeftTitle>{t('signupIntro')}</SignupLeftTitle>
                <Line />
              </Container>
              <Gap size={3} />
              <BodyText variant="body1">
                <Trans i18nKey="signupBlurb" />
              </BodyText>
              <Gap size={3} />
            </Container>
            <ImagesBrandsContainer>
              <Container>
                <Gap size={1} />
                <BrandTitle>{t('signupBrandTitle')}</BrandTitle>
                <Gap size={2} />
                <img src={signupLogos} alt={t('signupBrandsImages')} />
              </Container>
            </ImagesBrandsContainer>
            <Gap size={4} />
            <ClosingTitle>{t('signupClosingText')}</ClosingTitle>
          </Grid>
          <Grid item sm={6} xs={12}>
            <MainTitle color="textPrimary" align="center">
              {t('signupTitle')}
            </MainTitle>
            <Gap size={2} />
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} sm={11}>
                <FormWrapper>
                  {error && (
                    <>
                      <Alert severity="error">
                        <Trans
                          i18nKey={error}
                          components={[
                            <StyledLink
                              key="shop-id"
                              href="mailto:support@faslet.me"
                            />
                          ]}
                        />
                      </Alert>
                      <Gap size={2} />
                      <Row>
                        <Spacer />
                        <Hidden smUp>
                          <img alt={t(error)} src="/boo.gif" width="100%" />
                        </Hidden>
                        <Hidden smDown>
                          <img alt={t(error)} src="/boo.gif" width="400px" />
                        </Hidden>
                        <Spacer />
                      </Row>
                    </>
                  )}
                  {!error && (
                    <form
                      id="faslet-portal-signup-form"
                      onSubmit={createStoreAndUser}
                    >
                      <Gap />
                      <Typography variant="h5" color="textPrimary">
                        {t('enterDetails')}
                      </Typography>
                      <Gap size={2} />
                      <StyledTextField
                        fullWidth
                        label={t('storeName')}
                        id="company-name"
                        name="company-name"
                        required
                        onChange={(
                          event: React.ChangeEvent<{ value: string }>
                        ) => setRetailerName(event.target.value)}
                        variant="outlined"
                        value={retailerName}
                        tabIndex={0}
                        onKeyDown={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (event.key === 'Enter' && isFirstStepComplete()) {
                            gotoSecondStep();
                          }
                        }}
                        autoFocus
                      />
                      <Gap size={3} />
                      <StyledTextField
                        fullWidth
                        label={t('storeUrl')}
                        required
                        error={!!retailerUrl.length && !urlIsValid(retailerUrl)}
                        onChange={(
                          event: React.ChangeEvent<{ value: string }>
                        ) => setRetailerUrl(event.target.value)}
                        variant="outlined"
                        value={retailerUrl}
                        tabIndex={1}
                        id="domain"
                        name="domain"
                        onKeyDown={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (event.key === 'Enter' && isFirstStepComplete()) {
                            gotoSecondStep();
                          }
                        }}
                      />
                      <Gap size={3} />
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <StyledTextField
                            fullWidth
                            label={t('ownerFirstName')}
                            required
                            onChange={(
                              event: React.ChangeEvent<{ value: string }>
                            ) => setFirstName(event.target.value)}
                            variant="outlined"
                            value={firstName}
                            tabIndex={1}
                            id="first-name"
                            name="first-name"
                            onKeyDown={(
                              event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (
                                event.key === 'Enter' &&
                                isFirstStepComplete()
                              ) {
                                gotoSecondStep();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <StyledTextField
                            fullWidth
                            label={t('ownerLastName')}
                            required
                            onChange={(
                              event: React.ChangeEvent<{ value: string }>
                            ) => setLastName(event.target.value)}
                            variant="outlined"
                            value={lastName}
                            tabIndex={1}
                            id="last-name"
                            name="last-name"
                            onKeyDown={(
                              event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                              if (
                                event.key === 'Enter' &&
                                isFirstStepComplete()
                              ) {
                                gotoSecondStep();
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Gap size={3} />
                      <StyledTextField
                        fullWidth
                        label={t('ownerEmail')}
                        id="email"
                        name="email"
                        required
                        error={
                          !!ownerEmail.length &&
                          (!emailIsValid(ownerEmail) ||
                            !emailServerAllowed(ownerEmail))
                        }
                        helperText={
                          !emailServerAllowed(ownerEmail)
                            ? t('useCompanyEmail')
                            : null
                        }
                        onChange={(
                          event: React.ChangeEvent<{ value: string }>
                        ) => setOwnerEmail(event.target.value)}
                        variant="outlined"
                        value={ownerEmail}
                        onKeyDown={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (event.key === 'Enter' && isFirstStepComplete()) {
                            gotoSecondStep();
                          }
                        }}
                        tabIndex={2}
                      />
                      <Gap size={3} />
                      <StyledTextField
                        fullWidth
                        label={t('ownerPhoneNumber')}
                        onChange={(
                          event: React.ChangeEvent<{ value: string }>
                        ) => setPhoneNumber(event.target.value)}
                        variant="outlined"
                        required
                        id="phone-number"
                        name="phone-number"
                        value={phoneNumber}
                        onKeyDown={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (event.key === 'Enter' && isFirstStepComplete()) {
                            gotoSecondStep();
                          }
                        }}
                        tabIndex={3}
                      />
                      <Gap size={3} />
                      <FormControl fullWidth>
                        <InputLabel id="annual-revenue-label">
                          {t('annualRevenue')} *
                        </InputLabel>
                        <StyledSelect
                          id="annual-revenue-select"
                          name="annualrevenue"
                          data-hs-field="annualrevenue"
                          label={t('annualRevenue')}
                          labelId="annual-revenue-label"
                          aria-labelledby="annual-revenue-label"
                          inputProps={{
                            id: 'annualrevenue',
                            name: 'annualrevenue'
                          }}
                          value={annualRevenue}
                          onChange={(event: SelectChangeEvent<number | null>) =>
                            setAnnualRevenue(Number(event.target.value))
                          }
                          variant="outlined"
                          required
                          tabIndex={4}
                        >
                          <MenuItem value="1000000">€0 - €1,000,000</MenuItem>
                          <MenuItem value="5000000">
                            €1,000,000 - €5,000,000
                          </MenuItem>
                          <MenuItem value="10000000">
                            €5,000,000 - €10,000,000
                          </MenuItem>
                          <MenuItem value="50000000">
                            €10,000,000 - €50,000,000
                          </MenuItem>
                          <MenuItem value="500000000">
                            €50,000,000 - €500,000,000
                          </MenuItem>
                          <MenuItem value="1000000000">€500,000,000+</MenuItem>
                        </StyledSelect>
                      </FormControl>
                      <Gap size={3} />
                      <StyledTextField
                        fullWidth
                        label={t('couponCode')}
                        id="coupon"
                        name="coupon"
                        helperText={t('enterCouponCodeSignupInfo')}
                        onChange={(
                          event: React.ChangeEvent<{ value: string }>
                        ) => setCouponCode(event.target.value)}
                        variant="outlined"
                        value={couponCode}
                        onKeyDown={(
                          event: React.KeyboardEvent<HTMLInputElement>
                        ) => {
                          if (event.key === 'Enter' && isFirstStepComplete()) {
                            gotoSecondStep();
                          }
                        }}
                        tabIndex={5}
                      />
                      <Gap size={3} />
                      <TermsOfService variant="caption">
                        <Checkbox
                          onChange={event =>
                            setTermsAgreed(event.target.checked)
                          }
                          aria-labelledby="terms-label"
                          id="accepted-terms-of-service"
                          name="accepted-terms-of-service"
                        />
                        <Trans
                          id="terms-label"
                          i18nKey="signupTerms"
                          components={[
                            <StyledLink
                              key="pp"
                              target="_blank"
                              href="https://site.faslet.me/terms-of-services"
                            />
                          ]}
                        />
                      </TermsOfService>
                      <Gap />
                      <Row>
                        <PrivacyNotice variant="caption">
                          <Trans
                            i18nKey="privacyNotice"
                            components={[
                              <StyledLink
                                key="pp"
                                target="_blank"
                                href="https://site.faslet.me/privacy-policy"
                              />
                            ]}
                          />
                        </PrivacyNotice>
                      </Row>
                      <Gap size={2} />
                      <Row>
                        <Spacer />
                        <Button
                          disabled={
                            !urlIsValid(retailerUrl) ||
                            !retailerName ||
                            !termsAgreed ||
                            !ownerEmail ||
                            !emailIsValid(ownerEmail) ||
                            !emailServerAllowed(ownerEmail) ||
                            !phoneNumber ||
                            !annualRevenue ||
                            loading
                          }
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          {t('joinFaslet')}
                        </Button>
                      </Row>
                    </form>
                  )}
                </FormWrapper>
              </Grid>
            </Grid>
            <Gap size={5} />
          </Grid>
        </Grid>
      </SignupContainer>
    </RootContainer>
  );
}
