import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Slider,
  Select,
  SelectChangeEvent,
  MenuItem,
  Collapse
} from '@mui/material';
import {
  FormatAlignLeftRounded as FormatLeftIcon,
  FormatAlignCenterRounded as FormatCenterIcon,
  FormatAlignRightRounded as FormatRightIcon,
  AutofpsSelectRounded as FormatAutoIcon
} from '@mui/icons-material';
import styled from '@emotion/styled';

import {
  FormRow,
  FormLabel,
  FormFieldWrapper,
  FormSectionTitle,
  FormTextInput,
  FormNumberInput,
  FormSection,
  FormSubsection,
  FormSectionSubtitle
} from './form-input';

import { ColorPicker } from './color-picker';
import { Row, Container } from './layout';
import { Gap, Spacer } from './spacer';

import {
  Alignment,
  ButtonCallToAction,
  Features,
  getCdnPath,
  getS3Path,
  getWidgetCdnPath,
  isNotNullOrUndefined,
  openInNewTab,
  SizeImpactReminder,
  useErrorNotification,
  useSpot,
  WidgetConfiguration
} from '../framework';
import { Line } from './styled';
import { languages } from 'screens/retailer/localization-helpers';

const LayoutEditor = styled(Container)``;

const LayoutEditorRow = styled(Row)`
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
`;

const LayoutSide = styled.div`
  flex: 0 0 60px;

  &:first-child {
  }
`;

const LayoutMiddle = styled.div`
  flex: 0 0 60px;
`;

const LayoutSpacingVertical = styled.div<{ active?: boolean }>`
  background: ${p => (p.active ? '#ffb123' : '#ffdfa5')};
  flex: 0 0 112px;
  height: 8px;
`;

const LayoutSpacingHorizontal = styled.div<{ active?: boolean }>`
  background: ${p => (p.active ? '#ffb123' : '#ffdfa5')};
  flex: 0 0 8px;
  height: 40px;
  margin: 4px 0;
`;

const LayoutButtonWrapper = styled.div`
  flex: 0 0 136px;
`;

const LayoutButtonPlaceholder = styled(Spacer)`
  margin: 4px;
  background: ${p => p.theme.palette.primary.main};
`;

const LayoutEditorNumberInput = styled(FormNumberInput)`
  input {
    padding: 8px 4px 8px 14px;
  }
`;

const EditableJsSnippet = styled.textarea`
  flex: 1 1 auto;
  position: relative;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;

const PersonaSelect = styled(Select<string>)`
  .MuiSelect-select {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 176px;
    overflow: hidden;
    height: 80px;

    img {
      position: absolute;
      top: 0;
      left: 8px;
      max-width: 160px;
      height: 100%;
    }
  }
`;

const PersonaRow = styled(MenuItem)`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 176px;
  overflow: hidden;
  height: 80px;

  img {
    position: absolute;
    top: 0;
    left: 8px;
    max-width: 160px;
    height: 100%;
  }
`;

function reconsituteSpacing({
  top,
  left,
  bottom,
  right
}: {
  top: number;
  left: number;
  right: number;
  bottom: number;
}) {
  return `${top}px ${right}px ${bottom}px ${left}px`;
}

const defaultButtonCta: ButtonCallToAction = {
  enabled: false,
  delay: 2000,
  showAfterPageLoads: 2,
  showOnlyOnce: false,
  alignment: 'auto'
};

const defaultSizeImpactReminder: SizeImpactReminder = {
  enabled: false,
  showWhenCartDiffers: true,
  showWhenResultDiffers: true,
  alignment: 'auto'
};

const defaultSecondaryColor = '#EB5E55';

export interface WidgetBasicProps {
  retailerSlug: string;
  updateWidgetConfiguration: (
    widgetConfiguration: WidgetConfiguration | null
  ) => unknown;
  widgetConfiguration: WidgetConfiguration | null | undefined;
  isAdmin: () => boolean;
  hideTitle?: boolean;
  externallyChanged?: Partial<WidgetConfiguration>;
  features?: Features;
}

type ButtonMode =
  | 'fitTextMode'
  | 'fillParentMode'
  | 'buttonLessMode'
  | 'buttonAndResultMode';

const personas = [
  {
    name: 'Kate',
    url: `${getWidgetCdnPath()}/personas/kate.webp`
  },
  {
    name: 'Ashley',
    url: `${getWidgetCdnPath()}/personas/ashley.webp`
  },
  {
    name: 'Tasha',
    url: `${getWidgetCdnPath()}/personas/tasha.webp`
  },
  {
    name: 'Chris',
    url: `${getWidgetCdnPath()}/personas/chris.webp`
  },
  {
    name: 'Mark',
    url: `${getWidgetCdnPath()}/personas/mark.webp`
  },
  {
    name: 'Olli',
    url: `${getWidgetCdnPath()}/personas/olli.webp`
  },
  {
    name: 'Zack',
    url: `${getWidgetCdnPath()}/personas/zack.webp`
  }
];

export function WidgetConfigurationBasic({
  retailerSlug,
  updateWidgetConfiguration,
  widgetConfiguration,
  isAdmin,
  hideTitle,
  externallyChanged,
  features
}: WidgetBasicProps) {
  const { t } = useTranslation();
  const [focused, setFocused] = useState<
    'top' | 'left' | 'right' | 'bottom' | undefined
  >();

  const [buttonMode, setButtonMode] = useState<ButtonMode>('fitTextMode');
  const { raw } = useSpot();
  const { displayErrors, notification } = useErrorNotification();
  const filePickerRef = useRef<HTMLInputElement>(null);
  const currentCustomHeaderImage = useRef(widgetConfiguration?.headerImage);

  const handleHeaderImageUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      if (!file) {
        return;
      }

      if (widgetConfiguration) {
        const extRe = /(?:\.([^.]+))?$/;
        const fileExt = extRe.exec(file.name)?.[1] ?? 'png';

        const urlResult = await raw<{ url: string; cdnUrl: string }>(
          `retailer/${retailerSlug}/file-upload-url/custom-header-${Date.now()}.${fileExt}`
        );
        const url = urlResult?.url;
        const cdnUrl = urlResult?.cdnUrl;

        updateWidgetConfiguration({
          ...widgetConfiguration,
          headerImage: undefined
        });

        if (url) {
          try {
            const uploadResponse: Response = await fetch(url, {
              method: 'PUT',
              body: file
            });

            if (uploadResponse.ok) {
              currentCustomHeaderImage.current =
                `${cdnUrl}`?.replace(getCdnPath(), getS3Path()) ?? '';

              updateWidgetConfiguration({
                ...widgetConfiguration,
                headerImage: `${cdnUrl}`
              });
            }
          } catch (e) {
            displayErrors(e as Error[]);
          }
        }
      }
      if (filePickerRef.current) filePickerRef.current.value = '';
    },
    [
      raw,
      updateWidgetConfiguration,
      widgetConfiguration,
      retailerSlug,
      displayErrors,
      currentCustomHeaderImage
    ]
  );

  const handleButtonlessUpsell = useCallback(() => {
    openInNewTab(
      `mailto:sales@faslet.me?subject=Enable Buttonless Mode&body=I'd like to enable Buttonless mode in my store`
    );
  }, []);

  useEffect(() => {
    if (widgetConfiguration?.buttonless) {
      setButtonMode('buttonLessMode');
    } else if (
      widgetConfiguration?.buttonFillParent &&
      !widgetConfiguration?.showButtonAndResult
    ) {
      setButtonMode('fillParentMode');
    } else if (
      widgetConfiguration?.buttonFillParent &&
      widgetConfiguration?.showButtonAndResult
    ) {
      setButtonMode('buttonAndResultMode');
    } else if (
      !widgetConfiguration?.buttonFillParent &&
      !widgetConfiguration?.showButtonAndResult
    ) {
      setButtonMode('fitTextMode');
    }
  }, [widgetConfiguration]);

  const changeButtonMode = useCallback(
    (e: SelectChangeEvent<ButtonMode>) => {
      const newMode = e.target.value as ButtonMode;

      if (newMode === 'buttonLessMode') {
        if (isAdmin()) {
          updateWidgetConfiguration({
            ...widgetConfiguration,
            buttonless: true
          });
          setButtonMode(newMode);
        } else {
          handleButtonlessUpsell();
        }
      } else if (newMode === 'buttonAndResultMode') {
        updateWidgetConfiguration({
          ...widgetConfiguration,
          buttonFillParent: true,
          showButtonAndResult: true,
          buttonless: false
        });
        setButtonMode(newMode);
      } else if (newMode === 'fitTextMode') {
        updateWidgetConfiguration({
          ...widgetConfiguration,
          buttonFillParent: false,
          showButtonAndResult: false,
          buttonless: false
        });
        setButtonMode(newMode);
      } else if (newMode === 'fillParentMode') {
        updateWidgetConfiguration({
          ...widgetConfiguration,
          buttonFillParent: true,
          showButtonAndResult: false,
          buttonless: false
        });
        setButtonMode(newMode);
      }
    },
    [
      setButtonMode,
      updateWidgetConfiguration,
      widgetConfiguration,
      isAdmin,
      handleButtonlessUpsell
    ]
  );

  const updateCorners = useCallback(
    (e: SelectChangeEvent<string>) => {
      const newCorners = e.target.value;

      const elementCorners =
        newCorners === 'rounded' || newCorners === 'squarePopupRoundElements'
          ? 5
          : 0;
      const popupCorners =
        newCorners === 'rounded' || newCorners === 'roundedPopupSquareElements'
          ? 12
          : 0;

      updateWidgetConfiguration({
        ...widgetConfiguration,
        borderRadius: elementCorners,
        popupBorderRadius: popupCorners
      });
    },
    [updateWidgetConfiguration, widgetConfiguration]
  );

  const corners = useMemo(() => {
    if (
      !!widgetConfiguration?.borderRadius &&
      (widgetConfiguration.popupBorderRadius === undefined ||
        widgetConfiguration.popupBorderRadius > 0)
    ) {
      return 'rounded';
    } else if (
      widgetConfiguration?.borderRadius === 0 &&
      (widgetConfiguration.popupBorderRadius === undefined ||
        widgetConfiguration.popupBorderRadius > 0)
    ) {
      return 'roundedPopupSquareElements';
    } else if (
      widgetConfiguration?.borderRadius === 0 &&
      widgetConfiguration?.popupBorderRadius == 0
    ) {
      return 'square';
    } else if (
      !!widgetConfiguration?.borderRadius &&
      widgetConfiguration.popupBorderRadius === 0
    ) {
      return 'squarePopupRoundElements';
    }
  }, [
    widgetConfiguration?.borderRadius,
    widgetConfiguration?.popupBorderRadius
  ]);

  const spacing = useMemo(
    () =>
      (widgetConfiguration?.spacing ?? '0 0 0 0').split(' ').reduce(
        (accum, curr, index, arr) => {
          const currentValue = Number.parseFloat(curr);

          if (arr.length === 1) {
            return {
              top: currentValue,
              left: currentValue,
              right: currentValue,
              bottom: currentValue
            };
          }

          if (arr.length === 2) {
            // eslint-disable-next-line default-case
            switch (index) {
              case 0:
                return { ...accum, bottom: currentValue, top: currentValue };
              case 1:
                return { ...accum, left: currentValue, right: currentValue };
            }
          }

          // eslint-disable-next-line default-case
          switch (index) {
            case 0:
              return { ...accum, top: currentValue };
            case 1:
              return { ...accum, right: currentValue };
            case 2:
              return { ...accum, bottom: currentValue };
            case 3:
              return { ...accum, left: currentValue };
          }

          return accum;
        },
        { top: 0, left: 0, right: 0, bottom: 0 }
      ),
    [widgetConfiguration?.spacing]
  );

  function clickFilePicker() {
    filePickerRef.current?.click();
  }

  const onPersonaChosen = useCallback(
    (e: SelectChangeEvent<string>) => {
      if (e.target.value === 'custom') {
        return;
      }

      updateWidgetConfiguration({
        ...widgetConfiguration,
        headerImage: e.target.value
      });
    },
    [updateWidgetConfiguration, widgetConfiguration]
  );

  return (
    <>
      {!hideTitle && (
        <FormSectionTitle>{t('widgetConfiguration')}</FormSectionTitle>
      )}
      <FormSection>
        <FormSectionSubtitle>{t('general')}</FormSectionSubtitle>
        <FormRow highlighted={isNotNullOrUndefined(externallyChanged?.font)}>
          <FormLabel>{t('fontFace')}</FormLabel>
          <FormFieldWrapper>
            <FormTextInput
              onChange={(value: string) => {
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  font: value
                });
              }}
              value={widgetConfiguration?.font ?? ''}
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(externallyChanged?.colorPrimary)}
        >
          <FormLabel>{t('primaryColor')}</FormLabel>
          <FormFieldWrapper>
            <ColorPicker
              color={widgetConfiguration?.colorPrimary}
              onChange={(value: string | undefined) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  colorPrimary: value
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(externallyChanged?.colorSecondary)}
        >
          <FormLabel>{t('secondaryColor')}</FormLabel>
          <FormFieldWrapper>
            <ColorPicker
              color={
                widgetConfiguration?.colorSecondary ?? defaultSecondaryColor
              }
              onChange={(value: string | undefined) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  colorSecondary: value
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(externallyChanged?.fontColor)}
        >
          <FormLabel>{t('fontColor')}</FormLabel>
          <FormFieldWrapper>
            <ColorPicker
              color={
                !!widgetConfiguration?.fontColor
                  ? widgetConfiguration?.fontColor
                  : '#ffffff'
              }
              onChange={(newValue: string | undefined) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  fontColor: newValue
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(
            externallyChanged?.fontColorSecondary
          )}
        >
          <FormLabel>{t('fontColorSecondary')}</FormLabel>
          <FormFieldWrapper>
            <ColorPicker
              color={
                !!widgetConfiguration?.fontColorSecondary
                  ? widgetConfiguration?.fontColorSecondary
                  : '#ffffff'
              }
              onChange={(newValue: string | undefined) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  fontColorSecondary: newValue
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(externallyChanged?.bodyFontColor)}
        >
          <FormLabel>{t('bodyFontColor')}</FormLabel>
          <FormFieldWrapper>
            <ColorPicker
              color={
                !!widgetConfiguration?.bodyFontColor
                  ? widgetConfiguration?.bodyFontColor
                  : '#666459'
              }
              onChange={(newValue: string | undefined) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  bodyFontColor: newValue
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(
            externallyChanged?.addToCartButtonColor
          )}
        >
          <FormLabel>{t('addToCartButtonColor')}</FormLabel>
          <FormFieldWrapper>
            <ColorPicker
              color={
                !!widgetConfiguration?.addToCartButtonColor
                  ? widgetConfiguration?.addToCartButtonColor
                  : widgetConfiguration?.colorPrimary ?? '#50aa8d'
              }
              onChange={(newValue: string | undefined) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  addToCartButtonColor: newValue
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(
            externallyChanged?.addToCartFontColor
          )}
        >
          <FormLabel>{t('addToCartFontColor')}</FormLabel>
          <FormFieldWrapper>
            <ColorPicker
              color={
                !!widgetConfiguration?.addToCartFontColor
                  ? widgetConfiguration?.addToCartFontColor
                  : widgetConfiguration?.fontColor ?? '#50aa8d'
              }
              onChange={(newValue: string | undefined) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  addToCartFontColor: newValue
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(externallyChanged?.headerFontColor)}
        >
          <FormLabel>{t('headerFontColor')}</FormLabel>
          <FormFieldWrapper>
            <ColorPicker
              color={
                !!widgetConfiguration?.headerFontColor
                  ? widgetConfiguration?.headerFontColor
                  : '#666459'
              }
              onChange={(newValue: string | undefined) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  headerFontColor: newValue
                })
              }
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        {isAdmin() && (
          <>
            <FormRow
              highlighted={isNotNullOrUndefined(externallyChanged?.fIconColor)}
            >
              <FormLabel>{t('fIconColor')}</FormLabel>
              <FormFieldWrapper>
                <ColorPicker
                  color={widgetConfiguration?.fIconColor ?? '#50aa8d'}
                  onChange={(newValue: string | undefined) =>
                    updateWidgetConfiguration({
                      ...widgetConfiguration,
                      fIconColor: newValue
                    })
                  }
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap size={1} />
          </>
        )}
        <FormRow
          highlighted={
            isNotNullOrUndefined(externallyChanged?.borderRadius) ||
            isNotNullOrUndefined(externallyChanged?.popupBorderRadius)
          }
        >
          <FormLabel>{t('roundedCorners')}</FormLabel>
          <FormFieldWrapper>
            <Select fullWidth onChange={updateCorners} value={corners}>
              <MenuItem value="rounded">{t('rounded')}</MenuItem>
              <MenuItem value="square">{t('square')}</MenuItem>
              <MenuItem value="roundedPopupSquareElements">
                {t('roundedPopupSquareElements')}
              </MenuItem>
              <MenuItem value="squarePopupRoundElements">
                {t('squarePopupRoundElements')}
              </MenuItem>
            </Select>
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        {isAdmin() && (
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.blockEventsFromHiddenWidget
            )}
          >
            <FormLabel>{t('blockEventsFromHiddenWidget')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="blockEventsFromHiddenWidget"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    blockEventsFromHiddenWidget: event.target.checked
                  })
                }
                checked={
                  widgetConfiguration?.blockEventsFromHiddenWidget ?? false
                }
              />
            </FormFieldWrapper>
          </FormRow>
        )}
        <Gap size={1} />
        <FormRow highlighted={isNotNullOrUndefined(externallyChanged?.locale)}>
          <FormLabel>{t('defaultLanguage')}</FormLabel>
          <FormFieldWrapper>
            <Select
              disabled={!features?.languageChoice && !isAdmin()}
              value={widgetConfiguration?.locale ?? 'autoDetect'}
              onChange={(e: SelectChangeEvent<string>) => {
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  locale:
                    e.target.value !== 'autoDetect' ? e.target.value : undefined
                });
              }}
              fullWidth
            >
              <MenuItem key={'autoDetect'} value={'autoDetect'}>
                {t('autoDetectLocale')}
              </MenuItem>
              {languages.map(lang => (
                <MenuItem key={lang} value={lang}>
                  {t(lang)}
                </MenuItem>
              ))}
            </Select>
          </FormFieldWrapper>
        </FormRow>
      </FormSection>
      <FormSection>
        <FormSectionSubtitle>{t('button')}</FormSectionSubtitle>
        {buttonMode !== 'buttonLessMode' && (
          <>
            <FormRow
              highlighted={isNotNullOrUndefined(
                externallyChanged?.buttonFontSize
              )}
            >
              <FormLabel>{t('buttonFontSize')}</FormLabel>
              <FormFieldWrapper>
                <Slider
                  value={widgetConfiguration?.buttonFontSize ?? 16}
                  onChange={(_: unknown, newValue: number | number[]) =>
                    updateWidgetConfiguration({
                      ...widgetConfiguration,
                      buttonFontSize: newValue as number
                    })
                  }
                  valueLabelDisplay="auto"
                  step={1}
                  min={8}
                  max={32}
                  marks={[
                    { value: 8, label: 8 },
                    { value: 16, label: 16 },
                    { value: 24, label: 24 },
                    { value: 32, label: 32 }
                  ]}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap size={1} />
            <FormRow
              highlighted={isNotNullOrUndefined(
                externallyChanged?.buttonHeight
              )}
            >
              <FormLabel>{t('buttonHeight')}</FormLabel>
              <FormFieldWrapper>
                <Slider
                  value={widgetConfiguration?.buttonHeight ?? 40}
                  onChange={(_: unknown, newValue: number | number[]) =>
                    updateWidgetConfiguration({
                      ...widgetConfiguration,
                      buttonHeight: newValue as number
                    })
                  }
                  valueLabelDisplay="auto"
                  step={1}
                  min={20}
                  max={100}
                  marks={[
                    { value: 20, label: 20 },
                    { value: 40, label: 40 },
                    { value: 60, label: 60 },
                    { value: 80, label: 80 },
                    { value: 100, label: 100 }
                  ]}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap size={1} />
            <FormRow
              highlighted={isNotNullOrUndefined(
                externallyChanged?.buttonBackgroundColor
              )}
            >
              <FormLabel>{t('buttonBackgroundColor')}</FormLabel>
              <FormFieldWrapper>
                <Switch
                  name="buttonBackgroundColor"
                  color="primary"
                  onChange={(
                    event: React.ChangeEvent<{
                      checked: boolean;
                    }>
                  ) =>
                    updateWidgetConfiguration({
                      ...widgetConfiguration,
                      buttonBackgroundColor: event.target.checked
                        ? '#f0f0f0ff'
                        : '#f0f0f000'
                    })
                  }
                  checked={
                    !widgetConfiguration?.buttonBackgroundColor ||
                    widgetConfiguration.buttonBackgroundColor.slice(-2) !== '00'
                  }
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap size={1} />
          </>
        )}
        <FormRow
          highlighted={
            isNotNullOrUndefined(externallyChanged?.buttonFillParent) ||
            isNotNullOrUndefined(externallyChanged?.showButtonAndResult) ||
            isNotNullOrUndefined(externallyChanged?.buttonless)
          }
        >
          <FormLabel>{t('buttonMode')}</FormLabel>
          <FormFieldWrapper>
            <Select
              fullWidth
              onChange={changeButtonMode}
              value={buttonMode}
              disabled={!isAdmin() && buttonMode === 'buttonLessMode'}
            >
              <MenuItem value="fitTextMode">{t('fitTextMode')}</MenuItem>
              <MenuItem value="fillParentMode">{t('fillParentMode')}</MenuItem>
              <MenuItem value="buttonAndResultMode">
                {t('buttonAndResultMode')}
              </MenuItem>
              <MenuItem value="buttonLessMode">{t('buttonLessMode')}</MenuItem>
            </Select>
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        {buttonMode !== 'buttonLessMode' && (
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.buttonFillParent
                ? externallyChanged?.buttonContentAlignment
                : externallyChanged?.buttonAlignmentInParent
            )}
          >
            <FormLabel>
              {t(
                widgetConfiguration?.buttonFillParent &&
                  !widgetConfiguration?.showButtonAndResult
                  ? 'buttonContentAlignment'
                  : 'buttonAlignmentInParent'
              )}
            </FormLabel>
            <FormFieldWrapper>
              <ToggleButtonGroup
                value={
                  widgetConfiguration?.buttonFillParent &&
                  !widgetConfiguration?.showButtonAndResult
                    ? widgetConfiguration?.buttonContentAlignment ?? 'center'
                    : widgetConfiguration?.buttonAlignmentInParent ?? 'left'
                }
                exclusive
                onChange={(_: unknown, newAlignment: Alignment) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    [widgetConfiguration?.buttonFillParent &&
                    !widgetConfiguration?.showButtonAndResult
                      ? 'buttonContentAlignment'
                      : 'buttonAlignmentInParent']: newAlignment
                  })
                }
                aria-label="text alignment"
              >
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  title={t('left')}
                >
                  <FormatLeftIcon />
                </ToggleButton>
                <ToggleButton
                  value="center"
                  aria-label="centered"
                  title={t('center')}
                >
                  <FormatCenterIcon />
                </ToggleButton>
                <ToggleButton
                  value="right"
                  aria-label="right aligned"
                  title={t('right')}
                >
                  <FormatRightIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </FormFieldWrapper>
          </FormRow>
        )}
        <Gap size={1} />
        {buttonMode !== 'buttonLessMode' && !features?.uiRefresh2024 && (
          <>
            <FormRow
              highlighted={isNotNullOrUndefined(
                externallyChanged?.invertButton
              )}
            >
              <FormLabel>{t('invertButtonColors')}</FormLabel>
              <FormFieldWrapper>
                <Switch
                  name="invertButton"
                  color="primary"
                  onChange={(
                    event: React.ChangeEvent<{
                      checked: boolean;
                    }>
                  ) =>
                    updateWidgetConfiguration({
                      ...widgetConfiguration,
                      invertButton: event.target.checked
                    })
                  }
                  checked={!!widgetConfiguration?.invertButton}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap size={1} />
          </>
        )}
        {isAdmin() && <Gap size={1} />}
        {isAdmin() && buttonMode !== 'buttonLessMode' && (
          <>
            <FormRow
              highlighted={isNotNullOrUndefined(externallyChanged?.disableIcon)}
            >
              <FormLabel>{t('disableIcon')}</FormLabel>
              <FormFieldWrapper>
                <Switch
                  name="disableIcon"
                  color="primary"
                  onChange={(
                    event: React.ChangeEvent<{
                      checked: boolean;
                    }>
                  ) =>
                    updateWidgetConfiguration({
                      ...widgetConfiguration,
                      disableIcon: event.target.checked
                    })
                  }
                  checked={!!widgetConfiguration?.disableIcon}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap size={1} />
          </>
        )}
        {isAdmin() && <Gap size={1} />}
        {isAdmin() && buttonMode !== 'buttonLessMode' && (
          <>
            <FormRow
              highlighted={isNotNullOrUndefined(externallyChanged?.customIcon)}
            >
              <FormLabel>{t('customIcon')}</FormLabel>
              <FormFieldWrapper>
                <FormTextInput
                  onChange={(value: string) =>
                    updateWidgetConfiguration({
                      ...widgetConfiguration,
                      customIcon: value
                    })
                  }
                  value={widgetConfiguration?.customIcon ?? ''}
                />
              </FormFieldWrapper>
            </FormRow>
            <Gap size={1} />
          </>
        )}
        <FormSubsection
          hideBorder={!widgetConfiguration?.buttonCallToAction?.enabled}
        >
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.buttonCallToAction?.enabled
            )}
          >
            <FormLabel>{t('buttonCallToAction')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    buttonCallToAction: {
                      ...(widgetConfiguration?.buttonCallToAction ??
                        defaultButtonCta),
                      enabled: event.target.checked
                    }
                  })
                }
                checked={widgetConfiguration?.buttonCallToAction?.enabled}
              />
            </FormFieldWrapper>
          </FormRow>
          <Collapse in={widgetConfiguration?.buttonCallToAction?.enabled}>
            <FormSection>
              <FormRow
                highlighted={isNotNullOrUndefined(
                  externallyChanged?.buttonCallToAction?.delay
                )}
              >
                <FormLabel>{t('buttonCallToActionPageDelay')}</FormLabel>
                <FormFieldWrapper>
                  <Select
                    fullWidth
                    onChange={(event: SelectChangeEvent<number>) =>
                      updateWidgetConfiguration({
                        ...widgetConfiguration,
                        buttonCallToAction: {
                          ...(widgetConfiguration?.buttonCallToAction ??
                            defaultButtonCta),
                          delay: Number(event.target.value)
                        }
                      })
                    }
                    value={widgetConfiguration?.buttonCallToAction?.delay ?? 0}
                  >
                    <MenuItem value="0">0 seconds</MenuItem>
                    <MenuItem value="1000">1 second</MenuItem>
                    <MenuItem value="2000">2 seconds</MenuItem>
                    <MenuItem value="3000">3 seconds</MenuItem>
                    <MenuItem value="4000">4 seconds</MenuItem>
                    <MenuItem value="5000">5 seconds</MenuItem>
                  </Select>
                </FormFieldWrapper>
              </FormRow>
              <FormRow
                highlighted={isNotNullOrUndefined(
                  externallyChanged?.buttonCallToAction?.showAfterPageLoads
                )}
              >
                <FormLabel>{t('buttonCallToActionPageLoads')}</FormLabel>
                <FormFieldWrapper>
                  <Select
                    fullWidth
                    onChange={(event: SelectChangeEvent<number>) =>
                      updateWidgetConfiguration({
                        ...widgetConfiguration,
                        buttonCallToAction: {
                          ...(widgetConfiguration?.buttonCallToAction ??
                            defaultButtonCta),
                          showAfterPageLoads: Number(event.target.value)
                        }
                      })
                    }
                    value={
                      widgetConfiguration?.buttonCallToAction
                        ?.showAfterPageLoads ?? 0
                    }
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                  </Select>
                </FormFieldWrapper>
              </FormRow>
              <FormRow
                highlighted={isNotNullOrUndefined(
                  externallyChanged?.buttonCallToAction?.showOnlyOnce
                )}
              >
                <FormLabel>{t('buttonCallToActionShowOnlyOnce')}</FormLabel>
                <FormFieldWrapper>
                  <Switch
                    color="primary"
                    onChange={(
                      event: React.ChangeEvent<{
                        checked: boolean;
                      }>
                    ) =>
                      updateWidgetConfiguration({
                        ...widgetConfiguration,
                        buttonCallToAction: {
                          ...(widgetConfiguration?.buttonCallToAction ??
                            defaultButtonCta),
                          showOnlyOnce: event.target.checked
                        }
                      })
                    }
                    checked={
                      widgetConfiguration?.buttonCallToAction?.showOnlyOnce
                    }
                  />
                </FormFieldWrapper>
              </FormRow>
              <FormRow
                highlighted={isNotNullOrUndefined(
                  externallyChanged?.buttonCallToAction?.alignment
                )}
              >
                <FormLabel>{t('buttonCallToActionAlignment')}</FormLabel>
                <FormFieldWrapper>
                  <ToggleButtonGroup
                    value={
                      widgetConfiguration?.buttonCallToAction?.alignment ??
                      'auto'
                    }
                    exclusive
                    onChange={(_: unknown, newAlignment: Alignment) =>
                      updateWidgetConfiguration({
                        ...widgetConfiguration,
                        buttonCallToAction: {
                          ...(widgetConfiguration?.buttonCallToAction ??
                            defaultButtonCta),
                          alignment: newAlignment
                        }
                      })
                    }
                    aria-label="popup alignment"
                  >
                    <ToggleButton
                      value="auto"
                      aria-label="left aligned"
                      title={t('auto')}
                    >
                      <FormatAutoIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="left"
                      aria-label="left aligned"
                      title={t('left')}
                    >
                      <FormatLeftIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="center"
                      aria-label="centered"
                      title={t('center')}
                    >
                      <FormatCenterIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="right"
                      aria-label="right aligned"
                      title={t('right')}
                    >
                      <FormatRightIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormFieldWrapper>
              </FormRow>
            </FormSection>
          </Collapse>
        </FormSubsection>
        {isAdmin() && (
          <>
            <Gap size={1} />
            <FormSubsection
              hideBorder={!widgetConfiguration?.sizeImpactReminder?.enabled}
            >
              <FormRow
                highlighted={isNotNullOrUndefined(
                  externallyChanged?.sizeImpactReminder?.enabled
                )}
              >
                <FormLabel>{t('sizeImpactReminder')}</FormLabel>
                <FormFieldWrapper>
                  <Switch
                    color="primary"
                    onChange={(
                      event: React.ChangeEvent<{
                        checked: boolean;
                      }>
                    ) =>
                      updateWidgetConfiguration({
                        ...widgetConfiguration,
                        sizeImpactReminder: {
                          ...(widgetConfiguration?.sizeImpactReminder ??
                            defaultSizeImpactReminder),
                          enabled: event.target.checked
                        }
                      })
                    }
                    checked={widgetConfiguration?.sizeImpactReminder?.enabled}
                  />
                </FormFieldWrapper>
              </FormRow>
              <Collapse in={widgetConfiguration?.sizeImpactReminder?.enabled}>
                <FormSection>
                  <FormRow
                    highlighted={isNotNullOrUndefined(
                      externallyChanged?.sizeImpactReminder
                        ?.showWhenResultDiffers
                    )}
                  >
                    <FormLabel>
                      {t('sizeImpactReminderWhenResultDiffers')}
                    </FormLabel>
                    <FormFieldWrapper>
                      <Switch
                        color="primary"
                        onChange={(
                          event: React.ChangeEvent<{
                            checked: boolean;
                          }>
                        ) =>
                          updateWidgetConfiguration({
                            ...widgetConfiguration,
                            sizeImpactReminder: {
                              ...(widgetConfiguration?.sizeImpactReminder ??
                                defaultSizeImpactReminder),
                              showWhenResultDiffers: event.target.checked
                            }
                          })
                        }
                        checked={
                          widgetConfiguration?.sizeImpactReminder
                            ?.showWhenResultDiffers
                        }
                      />
                    </FormFieldWrapper>
                  </FormRow>
                  <FormRow
                    highlighted={isNotNullOrUndefined(
                      externallyChanged?.sizeImpactReminder?.showWhenCartDiffers
                    )}
                  >
                    <FormLabel>
                      {t('sizeImpactReminderWhenCartDiffers')}
                    </FormLabel>
                    <FormFieldWrapper>
                      <Switch
                        color="primary"
                        onChange={(
                          event: React.ChangeEvent<{
                            checked: boolean;
                          }>
                        ) =>
                          updateWidgetConfiguration({
                            ...widgetConfiguration,
                            sizeImpactReminder: {
                              ...(widgetConfiguration?.sizeImpactReminder ??
                                defaultSizeImpactReminder),
                              showWhenCartDiffers: event.target.checked
                            }
                          })
                        }
                        checked={
                          widgetConfiguration?.sizeImpactReminder
                            ?.showWhenCartDiffers
                        }
                      />
                    </FormFieldWrapper>
                  </FormRow>
                  <FormRow
                    highlighted={isNotNullOrUndefined(
                      externallyChanged?.sizeImpactReminder?.alignment
                    )}
                  >
                    <FormLabel>{t('sizeImpactReminderAlignment')}</FormLabel>
                    <FormFieldWrapper>
                      <ToggleButtonGroup
                        value={
                          widgetConfiguration?.sizeImpactReminder?.alignment ??
                          'auto'
                        }
                        exclusive
                        onChange={(_: unknown, newAlignment: Alignment) =>
                          updateWidgetConfiguration({
                            ...widgetConfiguration,
                            sizeImpactReminder: {
                              ...(widgetConfiguration?.sizeImpactReminder ??
                                defaultSizeImpactReminder),
                              alignment: newAlignment
                            }
                          })
                        }
                        aria-label="popup alignment"
                      >
                        <ToggleButton
                          value="auto"
                          aria-label="left aligned"
                          title={t('auto')}
                        >
                          <FormatAutoIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="left"
                          aria-label="left aligned"
                          title={t('left')}
                        >
                          <FormatLeftIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="center"
                          aria-label="centered"
                          title={t('center')}
                        >
                          <FormatCenterIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="right"
                          aria-label="right aligned"
                          title={t('right')}
                        >
                          <FormatRightIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </FormFieldWrapper>
                  </FormRow>
                  {isAdmin() && (
                    <>
                      <FormRow>
                        <FormLabel>{t('variantJs')}</FormLabel>
                        <EditableJsSnippet
                          className="highlight-javascript"
                          rows={10}
                          value={
                            widgetConfiguration?.sizeImpactReminder?.variantJs
                          }
                          onChange={(
                            event: React.ChangeEvent<{ value: string }>
                          ) =>
                            updateWidgetConfiguration({
                              ...widgetConfiguration,
                              sizeImpactReminder: {
                                ...(widgetConfiguration?.sizeImpactReminder ??
                                  defaultSizeImpactReminder),
                                variantJs: event.target.value
                              }
                            })
                          }
                        />
                      </FormRow>
                      <FormRow>
                        <FormLabel>{t('cartJs')}</FormLabel>
                        <EditableJsSnippet
                          className="highlight-javascript"
                          rows={10}
                          value={
                            widgetConfiguration?.sizeImpactReminder?.cartJs
                          }
                          onChange={(
                            event: React.ChangeEvent<{ value: string }>
                          ) =>
                            updateWidgetConfiguration({
                              ...widgetConfiguration,
                              sizeImpactReminder: {
                                ...(widgetConfiguration?.sizeImpactReminder ??
                                  defaultSizeImpactReminder),
                                cartJs: event.target.value
                              }
                            })
                          }
                        />
                      </FormRow>
                    </>
                  )}
                </FormSection>
              </Collapse>
            </FormSubsection>
          </>
        )}
        <Gap size={1} />
        {buttonMode !== 'buttonLessMode' && (
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.spacing)}
          >
            <FormLabel>{t('spacing')}</FormLabel>
            <FormFieldWrapper>
              <LayoutEditor>
                <LayoutEditorRow>
                  <LayoutSide />
                  <LayoutMiddle>
                    <LayoutEditorNumberInput
                      value={spacing.top}
                      onChange={(value: number) =>
                        updateWidgetConfiguration({
                          ...widgetConfiguration,
                          spacing: reconsituteSpacing({
                            ...spacing,
                            top: value
                          })
                        })
                      }
                      onFocus={() => setFocused('top')}
                    />
                  </LayoutMiddle>
                  <LayoutSide />
                </LayoutEditorRow>
                <LayoutEditorRow>
                  <LayoutSide>
                    <LayoutEditorNumberInput
                      value={spacing.left}
                      onChange={(value: number) =>
                        updateWidgetConfiguration({
                          ...widgetConfiguration,
                          spacing: reconsituteSpacing({
                            ...spacing,
                            left: value
                          })
                        })
                      }
                      onFocus={() => setFocused('left')}
                    />
                  </LayoutSide>
                  <LayoutButtonWrapper>
                    <Row>
                      <Spacer />
                      <LayoutSpacingVertical active={focused === 'top'} />
                      <Spacer />
                    </Row>
                    <Row>
                      <LayoutSpacingHorizontal active={focused === 'left'} />
                      <LayoutButtonPlaceholder />
                      <LayoutSpacingHorizontal active={focused === 'right'} />
                    </Row>
                    <Row>
                      <Spacer />
                      <LayoutSpacingVertical active={focused === 'bottom'} />
                      <Spacer />
                    </Row>
                  </LayoutButtonWrapper>
                  <LayoutSide>
                    <LayoutEditorNumberInput
                      value={spacing.right}
                      onChange={(value: number) =>
                        updateWidgetConfiguration({
                          ...widgetConfiguration,
                          spacing: reconsituteSpacing({
                            ...spacing,
                            right: value
                          })
                        })
                      }
                      onFocus={() => setFocused('right')}
                    />
                  </LayoutSide>
                </LayoutEditorRow>
                <LayoutEditorRow>
                  <LayoutSide />
                  <LayoutMiddle>
                    <LayoutEditorNumberInput
                      value={spacing.bottom}
                      onChange={(value: number) =>
                        updateWidgetConfiguration({
                          ...widgetConfiguration,
                          spacing: reconsituteSpacing({
                            ...spacing,
                            bottom: value
                          })
                        })
                      }
                      onFocus={() => setFocused('bottom')}
                    />
                  </LayoutMiddle>
                  <LayoutSide />
                </LayoutEditorRow>
              </LayoutEditor>
            </FormFieldWrapper>
          </FormRow>
        )}
        {isAdmin() && <Gap size={1} />}
        {isAdmin() && (
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.buttonParentSelector
            )}
          >
            <FormLabel>{t('buttonParentSelector')}</FormLabel>
            <FormFieldWrapper>
              <FormTextInput
                onChange={(value: string) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    buttonParentSelector: value
                  })
                }
                value={widgetConfiguration?.buttonParentSelector ?? ''}
              />
            </FormFieldWrapper>
          </FormRow>
        )}
        {isAdmin() && <Gap size={1} />}
        {isAdmin() && (
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.buttonClass)}
          >
            <FormLabel>{t('buttonCssClass')}</FormLabel>
            <FormFieldWrapper>
              <FormTextInput
                onChange={(value: string) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    buttonClass: value
                  })
                }
                value={widgetConfiguration?.buttonClass ?? ''}
              />
            </FormFieldWrapper>
          </FormRow>
        )}
        {isAdmin() && <Gap size={1} />}
        {isAdmin() && (
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.buttonStylesheet
            )}
          >
            <FormLabel>{t('buttonStylesheet')}</FormLabel>
            <FormFieldWrapper>
              <FormTextInput
                onChange={(value: string) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    buttonStylesheet: value
                  })
                }
                value={widgetConfiguration?.buttonStylesheet ?? ''}
              />
            </FormFieldWrapper>
          </FormRow>
        )}
      </FormSection>
      <FormSection hideBorder>
        <FormSectionSubtitle>{t('popup')}</FormSectionSubtitle>
        <FormRow
          highlighted={isNotNullOrUndefined(externallyChanged?.headerImage)}
        >
          <FormLabel>{t('headerImage')}</FormLabel>
          <FormFieldWrapper>
            <PersonaSelect
              fullWidth
              onChange={onPersonaChosen}
              value={
                widgetConfiguration?.headerImage
                  ? !personas.find(
                      p => p.url === widgetConfiguration?.headerImage
                    )
                    ? 'custom'
                    : widgetConfiguration?.headerImage
                  : `${getWidgetCdnPath()}/personas/kate.webp`
              }
            >
              {personas.map(persona => (
                <PersonaRow value={persona.url}>
                  <img src={persona.url} />
                  {persona.name}
                </PersonaRow>
              ))}
              <PersonaRow value="custom" onClick={clickFilePicker}>
                <img src={currentCustomHeaderImage.current} />
                {t('custom')}
              </PersonaRow>
            </PersonaSelect>
            <input
              ref={filePickerRef}
              type="file"
              onChange={handleHeaderImageUpload}
              style={{ display: 'none' }}
              accept={'image/*'}
            />
          </FormFieldWrapper>
        </FormRow>
        {!features?.uiRefresh2024 && (
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.disableBorder)}
          >
            <FormLabel>{t('disableBorder')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="disableBorder"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    disableBorder: event.target.checked
                  })
                }
                checked={widgetConfiguration?.disableBorder ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
        )}
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(
            externallyChanged?.disableUiShadows
          )}
        >
          <FormLabel>{t('disableUiShadows')}</FormLabel>
          <FormFieldWrapper>
            <Switch
              name="disableUiShadows"
              color="primary"
              onChange={(
                event: React.ChangeEvent<{
                  checked: boolean;
                }>
              ) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  disableUiShadows: event.target.checked
                })
              }
              checked={widgetConfiguration?.disableUiShadows ?? false}
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(
            externallyChanged?.disableBackgroundBlur
          )}
        >
          <FormLabel>{t('disableBackgroundBlur')}</FormLabel>
          <FormFieldWrapper>
            <Switch
              name="disableBackgroundBlur"
              color="primary"
              onChange={(
                event: React.ChangeEvent<{
                  checked: boolean;
                }>
              ) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  disableBackgroundBlur: event.target.checked
                })
              }
              checked={widgetConfiguration?.disableBackgroundBlur ?? false}
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(
            externallyChanged?.productImagePadding
          )}
        >
          <FormLabel>{t('productImagePadding')}</FormLabel>
          <FormFieldWrapper>
            <Switch
              name="productImagePadding"
              color="primary"
              onChange={(
                event: React.ChangeEvent<{
                  checked: boolean;
                }>
              ) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  productImagePadding: event.target.checked ? 5 : 0
                })
              }
              checked={!!widgetConfiguration?.productImagePadding}
            />
          </FormFieldWrapper>
        </FormRow>

        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(
            externallyChanged?.disableUnitSelector
          )}
        >
          <FormLabel>{t('disableUnitSelector')}</FormLabel>
          <FormFieldWrapper>
            <Switch
              name="disableUnitSelector"
              color="primary"
              onChange={(
                event: React.ChangeEvent<{
                  checked: boolean;
                }>
              ) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  disableUnitSelector: event.target.checked
                })
              }
              checked={widgetConfiguration?.disableUnitSelector ?? false}
            />
          </FormFieldWrapper>
        </FormRow>
        <Gap size={1} />
        <FormRow
          highlighted={isNotNullOrUndefined(
            externallyChanged?.disableBackToTop
          )}
        >
          <FormLabel>{t('disableBackToTop')}</FormLabel>
          <FormFieldWrapper>
            <Switch
              name="disableBackToTop"
              color="primary"
              onChange={(
                event: React.ChangeEvent<{
                  checked: boolean;
                }>
              ) =>
                updateWidgetConfiguration({
                  ...widgetConfiguration,
                  disableBackToTop: event.target.checked
                })
              }
              checked={widgetConfiguration?.disableBackToTop ?? false}
            />
          </FormFieldWrapper>
        </FormRow>
        {isAdmin() && <Gap size={1} />}
        {isAdmin() && (
          <FormRow
            highlighted={isNotNullOrUndefined(externallyChanged?.fasletOutlink)}
          >
            <FormLabel>{t('fasletOutlink')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="fasletOutlink"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    fasletOutlink: event.target.checked
                  })
                }
                checked={widgetConfiguration?.fasletOutlink ?? true}
              />
            </FormFieldWrapper>
          </FormRow>
        )}
        {isAdmin() && (
          <FormRow
            highlighted={isNotNullOrUndefined(
              externallyChanged?.disableAddToCart
            )}
          >
            <FormLabel>{t('disableAddToCart')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="disableAddToCart"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    disableAddToCart: event.target.checked
                  })
                }
                checked={widgetConfiguration?.disableAddToCart ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
        )}
      </FormSection>
      <Line />
      {isAdmin() && (
        <FormSection>
          <FormSectionSubtitle>{t('specialOptions')}</FormSectionSubtitle>
          <FormRow>
            <FormLabel>{t('chatMode')}</FormLabel>
            <FormFieldWrapper>
              <Switch
                name="chatMode"
                color="primary"
                onChange={(
                  event: React.ChangeEvent<{
                    checked: boolean;
                  }>
                ) =>
                  updateWidgetConfiguration({
                    ...widgetConfiguration,
                    chatMode: event.target.checked
                  })
                }
                checked={widgetConfiguration?.chatMode ?? false}
              />
            </FormFieldWrapper>
          </FormRow>
        </FormSection>
      )}
      {notification}
    </>
  );
}

export default WidgetConfigurationBasic;
